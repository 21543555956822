import './Search.css';
import { SyntheticEvent } from 'react';
import AdvancedSearchInputGroup from '../SearchInput/AdvancedSearchInputGroup';
import SearchInputGroup from '../SearchInput/SearchInputGroup';
import Authorization from '../../../security-profiles/models/Authorization';
import { useCurrentSearchType, useSearchProviderUpdater } from '../../hooks/useEarningsSearchProvider';
import { useTriggerAdvancedSearch } from '../../hooks/useAdvancedSearchCriteria';

interface Props {
  isOps?: boolean;
  securityProfileAuthorizations?: Authorization[];
}

const Search = (props: Props) => {
  const { isOps = false, securityProfileAuthorizations } = props;
  const searchType = useCurrentSearchType();
  const searchProviderUpdater = useSearchProviderUpdater();
  const { toggleTriggerAdvancedSearch } = useTriggerAdvancedSearch();

  function onSwitchChanges(e: SyntheticEvent<Element, Event>) {
    if (searchType === 'basic') {
      searchProviderUpdater('advanced');
      toggleTriggerAdvancedSearch(true);
    } else {
      searchProviderUpdater('basic');
    }
  }

  return (
    <div className="util-margin-horz-60 util-margin-top-30">
      <div style={{ margin: 'auto 0' }}>
        {useCurrentSearchType() === 'basic' ? (
          <SearchInputGroup isOps={isOps} securityProfileAuthorizations={securityProfileAuthorizations} />
        ) : (
          <AdvancedSearchInputGroup />
        )}
        <div className="util-margin-top-15">
          Switch to{' '}
          <a id="search-toggle" href="# " onClick={onSwitchChanges}>
            {useCurrentSearchType() === 'basic' ? 'Advanced Search' : 'Basic Search'}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Search;

import { retryAsync } from 'ts-retry';
import HubFetch from './hub-fetch';
import HubView from '../shared/models/HubView';

const baseUrl = process.env.REACT_APP_COMPENSATION_HUB_API;

async function get(): Promise<HubView | undefined> {
  try {
    return await retryAsync(
      async () => {
        const response = await HubFetch.hubFetch(`${baseUrl}/view`);
        return JSON.parse(response);
      },
      { delay: 1000, maxTry: 3 },
    );
  } catch (error) {
    return undefined;
  }
}

export default {
  get,
  baseUrl,
};

import { useCallback, useEffect, useState } from 'react';
import isEmpty from 'lodash.isempty';
import DisbursementPicker from '../disbursements/DisbursementPicker/DisbursementPicker';
import AdjustmentsApi from '../api/adjustments-api';
import DataCard from '../disbursements/DataCard/DataCard';
import DisbursementSummary from '../disbursements/models/DisbursementSummary';
import Link from '../shared/models/Link';
import Disbursement from '../disbursements/models/Disbursement';
import PayDetails, { CompensationType } from '../disbursements/PayDetails/PayDetails';
import ApiError from '../api/ApiError';
import Alert, { AlertProps } from '../shared/components/Alert/Alert';
import LoadingPage from '../shared/components/Loading/LoadingPage';
import AdjustmentsTable from '../adjustments/table/AdjustmentsTable';
import Column from '../shared/models/Column';
import AdvancedSearchCriteria from '../search/models/AdvancedSearchCriteria';
import { useAdvancedSearchCriteria, useTriggerAdvancedSearch } from '../search/hooks/useAdvancedSearchCriteria';

interface Props {
  // eslint-disable-next-line react/no-unused-prop-types
  isOps?: boolean;
  disbursementsLink: Link;
  columnDefinitions: Column[];
}

const AdjustmentsViewContainer = (props: Props) => {
  const { disbursementsLink, columnDefinitions, isOps } = props;
  const [disbursements, setDisbursements] = useState<DisbursementSummary[]>([]);
  const [currentDisbursement, setCurrentDisbursement] = useState<Disbursement>();
  const [alert, setAlert] = useState<AlertProps>({
    message: 'Please enter a search criteria and click on the Search button.',
    severity: 'info',
  });
  const [apiError, setApiError] = useState<ApiError | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDisbursementView, setIsDisbursementView] = useState<boolean>(true);
  const [adjustmentsPageLink, setAdjustmentsPageLink] = useState<Link>();
  const advancedSearchCriteria = useAdvancedSearchCriteria();

  const { toggleTriggerAdvancedSearch, triggerAdvancedSearch } = useTriggerAdvancedSearch();

  const callback = useCallback(
    async (searchCriteria: AdvancedSearchCriteria) => {
      let newView;
      try {
        setIsDisbursementView(true);

        if (!isEmpty(searchCriteria)) {
          setIsLoading(true);
          newView = await AdjustmentsApi.getDisbursementsViewAndSummaries(searchCriteria, disbursementsLink.href);
        }
        setApiError(undefined);
      } catch (e) {
        setApiError(e as ApiError);
        setIsLoading(false);
      }

      const newDisbursements = newView === undefined ? [] : newView.disbursementSummaries;
      let newCurrentDisbursement;
      if (newDisbursements.length > 0) {
        const disbursement = newDisbursements[newDisbursements.length - 1];
        newCurrentDisbursement = await AdjustmentsApi.getDisbursementByUrl(disbursement._links.disbursement);
      } else if (isOps && isEmpty(searchCriteria)) {
        setAlert({ message: 'Please enter a search criteria and click on the Search button.', severity: 'info' });
      } else {
        setAlert({ message: 'No disbursements matching search criteria.', severity: 'info' });
      }
      setCurrentDisbursement(newCurrentDisbursement);
      setDisbursements(newDisbursements);
      setIsLoading(false);
    },
    [disbursementsLink.href, isOps],
  );

  useEffect(() => {
    const performSearch = async (): Promise<void> => {
      if (advancedSearchCriteria && triggerAdvancedSearch) {
        await callback(advancedSearchCriteria);
        toggleTriggerAdvancedSearch(false);
      }
    };

    performSearch();
  }, [advancedSearchCriteria, callback, toggleTriggerAdvancedSearch, triggerAdvancedSearch]);

  useEffect(() => {
    setAdjustmentsPageLink(currentDisbursement?._links.advancedSearch);
  }, [currentDisbursement]);

  const handleDisbursementClickCallback = async (disbursementLink: Link) => {
    const disbursementDetail = await AdjustmentsApi.getDisbursementByUrl(disbursementLink);
    setCurrentDisbursement(disbursementDetail);
  };

  const disbursementPicker = (
    <DisbursementPicker
      disbursements={disbursements}
      handleDisbursementClickCallback={handleDisbursementClickCallback}
      alert={alert}
      hidePayMethod
      isOps
    />
  );

  const disbursementPayDetails = (
    <>
      <PayDetails disbursement={currentDisbursement as Disbursement} compensationType={CompensationType.ADJUSTMENT} />
      <div className="row">
        <div className="col-md-12">
          <div className="card-container">
            <DataCard label="Credits" amount={currentDisbursement?.totalCreditAmount} />
            <DataCard label="Debits" amount={currentDisbursement?.totalDebitAmount} />
            <DataCard label="Adjustments" amount={currentDisbursement?.totalEarnedAmount} />
          </div>
        </div>
      </div>
    </>
  );

  const adjustmentsTable = (
    <div className="row">
      <AdjustmentsTable adjustmentsPageLink={adjustmentsPageLink} columnDefinitions={columnDefinitions} />
    </div>
  );

  return (
    <div className="comp-hub-disbursements util-margin-50">
      <div className="row">
        <div className="col-md-12">
          {apiError && (
            <div className="no-print">
              <Alert severity="danger" message={apiError.message} errorList={apiError.errors} />
            </div>
          )}
          {!isLoading ? (
            <div>
              {/* begin disbursement-specific layer */}
              {!apiError && isDisbursementView && disbursementPicker}
              {currentDisbursement && isDisbursementView && disbursementPayDetails}
              {/* end disbursement-specific layer */}
              {(currentDisbursement || !isDisbursementView) && adjustmentsTable}
            </div>
          ) : (
            <LoadingPage />
          )}
        </div>
      </div>
    </div>
  );
};
export default AdjustmentsViewContainer;
